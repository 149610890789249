import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Content from '@solid-ui-blocks/Content/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes, normalizeContentfulFeatureNodes } from '@blocks-helpers'
import styles from './_styles'

const Mobile = props => {
  const { allBlockContent, allContentfulFeature } = props.data
  const templateContent = normalizeBlockContentNodes(allBlockContent?.nodes)
  const featureContent = normalizeContentfulFeatureNodes(allContentfulFeature?.nodes)

  return (
    <Layout {...props}>
      <Seo title={featureContent.hero.text[0].text} description={featureContent.hero.text[1].text} />
      {/* Modals */}
      <ModalWithTabs content={templateContent['authentication']} reverse />
      <ModalWithTabs content={templateContent['contact']} />
      <ModalSimple content={templateContent['advertisement']} />
      {/* Blocks */}
      <Header content={templateContent['header']} />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={featureContent.hero} reverse />
      </Container>
      <Footer content={templateContent['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageMobileContent {
    allBlockContent(
      filter: { page: { in: ["shared"] } }
    ) {
      nodes {
        ...BlockContent
      } 
    }
    allContentfulFeature(filter: {slug: {eq: "mobile"}}) {
      nodes {
        identifier: slug
        page: slug
        title
        subtitle {
          subtitle
        }
        hero {
          url 
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  }
`

export default Mobile
